@keyframes flash {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Invisible */
  }
}

.flashing-text {
  animation: flash 1s infinite; /* Adjust duration as needed */
  color: red; /* Flashing color */
}