@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5dc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.datepicker-refre
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  padding: 15px !important;
  outline: none;
}
.dat-picker-wrapper {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  input {
    font-family: ui-monospace !important;
  }
}
.icon_wrapper {
  z-index: 99999999;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 500px;
  background-color: rgba(7, 62, 0, 0.9);
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(10px);
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 10;
  transform: translateY(0);
}
@media only screen and (max-width: 1024px) {
  .tooltip .tooltip-text {
    right: 0;
    left: auto;
  }
}
@media only screen and (max-width: 575px) {
  .tooltip .tooltip-text {
    width: 300px;
  }
}
