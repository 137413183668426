.footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
}

.lingoFooterMark span {
    font-size: 30px;
    font-weight: 700;
    padding-left: 25px;
    color: #004225;
    border-left: 5px solid #004225;
    display: inline;
}