.breadcrumb-container {
  position: sticky;
  top: 0;
  background-color: #0042254f;
  padding: 15px;
  z-index: 1000;
  border-bottom: 1px solid #ddd;
  text-align: center; /* Center the text */
}
@media only screen and (max-width: 600px) {
  .breadcrumb-container{
    padding: 30px;
    padding-left: 12px;
    font-weight: 500;
  }
  
}

.breadcrumb {
  display: flex; /* Flexbox to arrange items horizontally */
  list-style: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.breadcrumb-item {
  margin-right: 10px; /* Space between items */
}
@media only screen and (max-width: 600px) {
  .breadcrumb-item{
    white-space: nowrap;
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: justify;

  }
 
}

.breadcrumb-item a {
  color: #004225;
  text-decoration: none;
  font-size: 18px;
  line-height: 28px;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
  text-decoration-color: #004225;
  
 
}

.breadcrumb-item::after {
  content: '>'; /* Adds a separator between breadcrumb items */
  margin-left: 10px;
}

.breadcrumb-item:last-child::after {
  content: ''; /* Remove the separator after the last item */
}

.breadcrumb-header {
  font-size: 18px; /* Adjust size as needed */
  color:#363232; /* Customize color */
  margin-bottom: 10px; /* Space below the header */
  text-align: center; /* Center the text */
  font-weight: bold; /* Make the text bold */
}
