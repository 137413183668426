.header {
    top: 0;
    left: 0;
    padding: 20px 10px;
}

.lingoMark {
    font-size: 30px;
    font-weight: 700;
    padding-left: 25px;
    color: #004225;
    border-left: 5px solid #004225;
    display: inline;
}

.navBar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    max-width: 900px;
}

.navBar ul li {
    float: left;
    cursor: pointer;
    /* padding: 4px 4px; */
}

.navBar ul li a {
    /* display: block; */
    color: #000;
    /* text-align: center; */
    padding: 5px 16px;
    text-decoration: none;
    position: relative;
}

.navBar li:hover {
    color: #004225;
}

.navBar li a:hover::after{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: 2px;
    background-color: #004225;
}

.loginArea {
    display: inline flex;
}