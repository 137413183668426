.App {
  position: relative;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slider-container .slick-slide > div {
  padding: 40px 15px 0;
  background: transparent;
}
.slider-container .slick-arrow {
  display: none !important;
}
.slider-container .slick-dots {
  position: unset;
  margin-top: 30px;
}
.slider-container .slick-dots li {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}
.slider-container .slick-dots li button {
  padding: 0;
  background-color: #004225;
  opacity: 0.2;
  border-radius: 100%;
  width: 100%;
  height: 100%;
}
.slider-container .slick-dots li.slick-active button {
  opacity: 1;
}
.slider-container .slick-dots li button::before {
  content: unset;
}
.fourLineElipcis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 150px;
}
@media only screen and (max-width: 720px) {
  .slider-container .slick-dots {
    margin-top: 15px;
  }
  .slider-container .slick-dots li {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
  .fourLineElipcis {
    height: 115px;
  }
}
@media only screen and (max-width: 575px) {
  .fourLineElipcis {
    height: auto;
  }
}